const data = {
  info: (
    <>
      <h1 style={{ fontSize: "1.5rem", fontWeight: "600" }}>Shubham Sahu</h1>
      <br></br>
      <ul className="aayu-det">
        <li>I am Shubham Sahu , Pre-Final year Student , B.Tech CSE AIML.</li>
        <li>Joint Secretary, UPES-CSI</li>
        <li>Hosted and Anchored International and National hackathons.</li>
        <li>Member of the college's Discipline Committee.</li>
      </ul>
    </>
  ),
};

export default data;
