const data = {
    info: (
      <>
        <h1 style={{ fontSize: "1.5rem", fontWeight: "600" }}>Swasti Rathee</h1>
        <br></br>
        <ul className="aayu-det">
          <li>I am Divyansh Jha, pre final year student, B.Tech CSE</li>
          <li>Joint Secretary, UPES-CSI</li>
        </ul>
      </>
    ),
  };
  
  export default data;
  