const data = {
  info: (
    <>
      <h1 style={{ fontSize: "1.5rem", fontWeight: "600" }}>Kashish Deol</h1>
      <br></br>
      <ul className="aayu-det">
        <li>
          I'm Rimjhim Gupta, pre-final year student of B.Tech CSE specializing
          in Cloud Computing and virtualization
        </li>
        <li>
          I'm thrilled to introduce myself as the Working Committee Head for the
          year 2023-24.
        </li>
        <li>
          My journey with CSI has been a fulfilling and enriching one, spanning
          over two years.
        </li>
        <li>
          I am truly honored to have the opportunity to work and contribute to
          this esteemed organization.
        </li>
      </ul>
    </>
  ),
};

export default data;
