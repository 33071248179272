const data={"info":<>
    <h1 style={{fontSize:"1.8rem",fontWeight:"600",textAlign:"left"}} st>Manvee Singh</h1> 
    <div className="d-flex justify-content-center align-items-center pratha" style={{gap:"5rem"}} >
    
    <div className="right-box-info">
    
    <br/>
    <ul className="pra-det">
        <li>I'm Manvee Singh, pre final year student, B. Tech CSE AIML</li>
        <li>Associate Events Head , UPES CSI.</li>
        <li>I have been part of the organizing committee for several UPES - CSI events.</li>
    </ul>
    </div>
    <div className="left-box-info">
    <br/>
    </div>
    </div>
    </>
    }
    
    export default data;