const data = [

    {
        "img": require('./Hackathon 6.0.jpg'),
        "eventname": "Hackathon 6.0",
        "evantDate": "25th May 2023",
        "description": "UPES-CSI brings you the biggest code fest with 48 hours of intense coding and amazing prizes. Hackathon has been our growing legacy for the past seven years. This event will challenge you to push your boundaries as only few can emerge victorious in this ultimate tech-fest.",
        "color": "blue",
        "imgo": [require('./dataJs/hackathon6.o/img (1).jpg'), require('./dataJs/hackathon6.o/img (1).png'), require('./dataJs/hackathon6.o/img (2).jpg'), require('./dataJs/hackathon6.o/img (2).png'), require('./dataJs/hackathon6.o/img (3).png')]
    },
    {
        "img": require('./image_6aec66bb-3be1-4c02-ae18-02a6c313df9920230905_230026.jpg'),
        "eventname": "Yugmak",
        "evantDate": "25th May 2023",
        "description": 'Just when you thought UPES-CSI was only a Technical Chapter, we bring for you "Yugmak". A 3-day event filled with all fun activities like Bidding Battle, Clickathon, Comic Chaos and many many more, this event unlike all other events invites participants to engage in photo clicking competitions, pitch business ideas, participate in caricature making which helps in an all-round development beyond the technical dimensions.',
        "color": "red",
        "imgo": [require('./dataJs/yugmak-21/IMG_0375.JPG'),
        require('./dataJs/yugmak-21/IMG_0392.JPG'),
        require('./dataJs/yugmak-21/IMG_0396.JPG'),
        require('./dataJs/yugmak-21/IMG_0421.JPG'),
        require('./dataJs/yugmak-21/IMG_0527.JPG'),
        require('./dataJs/yugmak-21/IMG_9944.JPG'),
        require('./dataJs/yugmak-21/_MG_9812.JPG'),
        require('./dataJs/yugmak-21/20220328131534_IMG_6016.JPG'),
        require('./dataJs/yugmak-21/20220328133718_IMG_6118.JPG')]

    },

]

module.exports = data