const data = {
    "info": <><h1 style={{ fontSize: "1.5rem", fontWeight: "600" }}>Rasya Mihir</h1>
        <br></br>
        <ul className="aayu-det">
            <li>
                I am Abhipriya Tyagi, final year student of B.Tech CSE with specialization in Cyber Security and Forensics.
            </li>
            <li>
                President, UPES-CSI
            </li>
            <li>
                First female president ever in UPES-CSI.
            </li>
            <li>
                Have been an organizer for multiple events in college.
            </li>
        </ul>
    </>
}

export default data;