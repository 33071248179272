const data = [
  {
    img: require("./WhatsApp Image 2023-09-08 at 22.53.25.jpg"),
    eventname: "CSR",
    evantDate: "25th May 2023",
    description:
      "It is an event where the committee visits orphanages or schools to actively engage the underprivileged in various fun and interesting activities. These events foster a sense of belonging and empowerment amongst the committee members to contribute meaningfully to the society and its upliftment.",
    color: "red",
    imgo: [
      require("./dataJs/csr-22/_MG_7166.JPG"),
      require("./dataJs/csr-22/_MG_7169.JPG"),
      require("./dataJs/csr-22/_MG_7240.JPG"),
      require("./dataJs/csr-22/_MG_7300.JPG"),
      require("./dataJs/csr-22/_MG_7585.JPG"),
      require("./dataJs/csr-22/_MG_7647.JPG"),
      require("./dataJs/csr-22/_MG_7714.JPG"),
      require("./dataJs/csr-22/_MG_7725.JPG"),
      require("./dataJs/csr-22/_MG_7738.JPG"),
      require("./dataJs/csr-22/_MG_7747.JPG"),
      require("./dataJs/csr-22/_MG_7749.JPG"),
      require("./dataJs/csr-22/_MG_7808.JPG"),
      require("./dataJs/csr-22/_MG_7940.JPG"),
      require("./dataJs/csr-22/_MG_7965.JPG"),
      require("./dataJs/csr-22/_MG_8007.JPG"),
      require("./dataJs/csr-22/_MG_8026.JPG"),
      require("./dataJs/csr-22/_MG_8030.JPG"),
      require("./dataJs/csr-22/IMG_6983.JPG"),
    ],
  },
  {
    img: require("../Images/WhatsApp Image 2023-09-03 at 9.24.53 PM.jpeg"),
    eventname: "ARENA 1.0",
    evantDate: "25th May 2023",
    description:
      "“Double the thrill, Double the kills”. UPES-CSI organized Arena 2.0, an epic gaming spectacle featuring not one but two exciting subevents: BGMI and Valorant. Gamers from all corners united to showcase their skills and battle it out for the amazing prizes.",
    color: "blue",
    imgo:[require("./dataJs/Arena-22/Screenshot (346).png"), require('./dataJs/Arena-22/WhatsApp Image 2022-07-23 at 2.25.19 AM (2).jpeg')]
  },
  {
    img: require("./GFG.jpg"),
    eventname: "Geeks for Geeks",
    evantDate: "25th May 2023",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    color: "red",
    imgo: [
      require("./dataJs/gfg/IMG-20220906-WA0006.jpg"),
      require("./dataJs/gfg/_MG_6232.JPG"),
      require("./dataJs/gfg/_MG_6381.JPG"),
      require("./dataJs/gfg/IMG_5962.JPG"),
      require("./dataJs/gfg/IMG_5998.JPG"),
      require("./dataJs/gfg/IMG_6017.JPG"),
    ],
  },

  {
    img: require("./image_6aec66bb-3be1-4c02-ae18-02a6c313df9920230905_230026.jpg"),
    eventname: "Yugmak",
    evantDate: "25th May 2023",
    description:
      'Just when you thought UPES-CSI was only a Technical Chapter, we bring for you "Yugmak". A 3-day event filled with all fun activities like Bidding Battle, Clickathon, Comic Chaos and many many more, this event unlike all other events invites participants to engage in photo clicking competitions, pitch business ideas, participate in caricature making which helps in an all-round development beyond the technical dimensions.',
    color: "red",
    imgo: [
      require("./dataJs/yugmak/img (1).JPG"),
      require("./dataJs/yugmak/img (2).JPG"),
      require("./dataJs/yugmak/img (3).JPG"),
      require("./dataJs/yugmak/img (4).JPG"),
      require("./dataJs/yugmak/img (5).JPG"),
      require("./dataJs/yugmak/img (6).jpg"),
      require("./dataJs/yugmak/img (7).jpg"),
      require("./dataJs/yugmak/img (8).JPG"),
      require("./dataJs/yugmak/img (9).JPG"),
      require("./dataJs/yugmak/img (10).JPG"),
      require("./dataJs/yugmak/img (11).JPG"),
      require("./dataJs/yugmak/img (12).JPG"),
      require("./dataJs/yugmak/img (13).JPG"),
      require("./dataJs/yugmak/img (14).JPG"),
      require("./dataJs/yugmak/img (15).JPG"),
    ],
  },
  {
    img: require("./WhatsApp Image 2023-09-05 at 22.57.42.jpg"),
    eventname: "Inkprints",
    evantDate: "25th May 2023",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    color: "red",
    imgo: [
      require("./dataJs/inkprints/_MG_9237.JPG"),
      require("./dataJs/inkprints/_MG_9320.JPG"),
      require("./dataJs/inkprints/_MG_9529.JPG"),
      require("./dataJs/inkprints/DSC_0346.JPG"),
      require("./dataJs/inkprints/DSC_0351.JPG"),
      require("./dataJs/inkprints/DSC09550.JPG"),
    ],
  },
];

module.exports=data;
