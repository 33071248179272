const data = {
    "info": <><h1 style={{ fontSize: "1.5rem", fontWeight: "600" }}>Rimjhim Gupta</h1>
        <br></br>
        <ul className="aayu-det">
            <li>
                I am Devashish Sharma, final-year student of B.Tech CSE specialised in Cyber Security and Forensics.
            </li>
            <li>
                I am the “Brand Ambassador” of School of Computer Science, UPES.
            </li>
            <li>
                Also, I am the “General Secretary” of UPES-CSI Student Chapter.
            </li>
            <li>
                Formerly held the position of “Convener” for UHackathon 4.0META, an international hackathon.
            </li>
        </ul>
    </>
}

export default data;