const data={"info":<>
    <h1 style={{fontSize:"1.8rem",fontWeight:"600",textAlign:"left"}} st>kartikay Pandey</h1> 
    <div className="d-flex justify-content-center align-items-center pratha" style={{gap:"5rem"}} >
    
    <div className="right-box-info">
    
    <br/>
    <ul className="pra-det">
    <li>I'm kartikay Pandey</li>
    </ul>
    </div>
    </div>
    </>
    }
    
    export default data;