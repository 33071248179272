const data={"info":<>
<h1 style={{fontSize:"1.8rem",fontWeight:"600",textAlign:"left"}} st>Events Committee</h1> 
<div className="d-flex justify-content-center align-items-center pratha" style={{gap:"5rem"}} >

<div className="right-box-info">

<br/>
<ul className="pra-det">
<li>I am Mahim Sharma, Pre-Final Year Student,B.Tech CSE (Full Stack AI).</li>
<li>Events Head, UPES CSI.</li>
<li> IAYP Bronze Awardee: I am a proud recipient of the Bronze Award at the International Award for Young People (IAYP)</li>
<li> State-Level Football Player: Beyond academics, I am a dedicated athlete representing my state at the football field, demonstrating leadership and teamwork.</li>

</ul>
</div>

</div>
</>
}

export default data;