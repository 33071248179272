const data={"info":<>
<h1 style={{fontSize:"1.8rem",fontWeight:"600",textAlign:"left"}} st>Ansh Garg</h1> 
<div className="d-flex justify-content-center align-items-center pratha" style={{gap:"5rem"}} >

<div className="right-box-info">

<br/>
<ul className="pra-det">
    <li>I'm Ansh Garg</li>
</ul>
</div>
</div>
</>
}

export default data;