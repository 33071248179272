const data={"info":<>
    <h1 style={{fontSize:"1.8rem",fontWeight:"600",textAlign:"left"}} st>Sparsh Jain</h1> 
    <div className="d-flex justify-content-center align-items-center pratha" style={{gap:"5rem"}} >
    
    <div className="right-box-info">
    
    <br/>
    <ul className="pra-det">
        <li>I am Sparsh Jain currently in my pre final year in Graphics & Gaming.</li>
        <li>Cinematography Head, UPES CSI</li>
    </ul>

    </div>
    </div>
    </>
}
    
    export default data;