import React from 'react'
import "./Collaborate.css"

import { db } from '../../firebase_config';


const Collaborate = () => {

  const handleSubmit = (e) => { 
    e.preventDefault();
  
  const uname = document.getElementById('uname').value;
  const email = document.getElementById('email').value;
  const message = document.getElementById('message').value;

  if (!uname || !email) {
    alert("Username and email are required.");
    return;
  }

  const data = {
    uname: uname,
    email: email,
    message: message
  }

  try {
 
    const querySnapshot = db.collection("Collaborate").where("email", "==", email).get();
    
    if(querySnapshot.size >= 10) {
      alert("Sufficient amount of emails with the same name already exists.");
      return;
    }


    db.collection("Collaborate").doc(email).set(data);
    
    alert("Message Sent");
  } catch (error) {
    console.error("Error sending message:", error);
  }
};


  return (
    <div className=" collaborate container pt-10  justify-center flex ">
      {/* <p>
        Our team is always looking for new ways to collaborate with other
        organizations and individuals. If you are interested in working with us,
        please fill out the form below and we will get back to you as soon as
        possible.
      </p> */}
      <div className="contact-content justify-center flex ">
        <div className="contact-left" style={{ backgroundColor: "white" }}>
          <div className="map-container">
          </div>
        </div>
        <div className="contact-right">
          <div className="contact-topic-text" style={{ color: "white" }}>
            Collaborate with Us
          </div>
          {/* <p className="contact-description">
            Our team is always looking for new ways to collaborate with other
            organizations and individuals. If you are interested in working with
            us, please fill out the form below and we will get back to you as
            soon as possible.
          </p> */}
          <form onSubmit={handleSubmit}>
            <div className="contact-input-box">
              <input
                id="uname"
                type="text"
                placeholder="Enter your Organization Name"
                style={{ color: "black" }}
              />
            </div>
            <div className="contact-input-box">
              <input
                id="email"
                type="text"
                placeholder="Enter your email"
                style={{ color: "black" }}
              />
            </div>
            <div className="contact-input-box contact-message-box">
              <input
                id="message"
                type="text"
                placeholder="Enter your Message"
                style={{ color: "black" }}
              />
            </div>
            <div className="contact-button">
              <input
                style={{ color: "black" }}
                type="button"
                name="sendmsg"
                value="Send Message"
                onClick={handleSubmit}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Collaborate