import React, { useState, useEffect } from "react";
import Events from "../../Components/Events/Events";
import Background from "../../Components/background/Background.jsx";
import Collaborate from "../../Components/Collaborate/Collaborate";


const Home = () => {
  const [isPopupVisible, setPopupVisible] = useState(false); // Start with popup hidden

  const handleClosePopup = () => {
    setPopupVisible(false);
  };

  // Use useEffect to delay the opening of the popup after a certain time (e.g., 5 seconds)
  useEffect(() => {
    const delay = 2000; // 5 seconds in milliseconds

    const timer = setTimeout(() => {
      setPopupVisible(true); // Show the popup after the delay
    }, delay);

    // Clear the timer if the component unmounts or if the popup is closed manually
    return () => {
      clearTimeout(timer);
    };
  }, []); // The empty dependency array ensures this effect runs only once

  return (
    <div>
      <div className="place-content-center bg-blend-screen">
        {/* <div className="mt-auto">{isPopupVisible && <Register onClose={handleClosePopup} />}</div> */}

        <Background />
        <Events />
        <Collaborate />
      </div>
    </div>
  );
};

export default Home;
