import React, { useState, useEffect } from "react";
import { useTable, useSortBy, useGlobalFilter } from "react-table";
import firebase from "firebase/app";
import "firebase/firestore";
import { CSVLink } from 'react-csv'; // Import CSVLink from react-csv

const firebaseConfig = {
  apiKey: "AIzaSyCeWFWaNeQI1RG61kV8aiC6xip5-eppIOs",
  authDomain: "csi-contactus.firebaseapp.com",
  projectId: "csi-contactus",
  storageBucket: "csi-contactus.appspot.com",
  messagingSenderId: "1059928571668",
  appId: "1:1059928571668:web:3b309eff02de3dff34cc01",
  measurementId: "G-89VFGSWV0E"
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}
const storage = firebase.storage(); // Initialize Firebase Storage

const AdminPage = () => {
  const [registrationData, setRegistrationData] = useState([]);
  const [loggedIn, setLoggedIn] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (loggedIn) {
      // Fetch registration data from Firestore
      const db = firebase.firestore();
      const registrationRef = db.collection("Members");

      registrationRef.get().then((querySnapshot) => {
        const data = [];
        querySnapshot.forEach((doc) => {
          const userData = { id: doc.id, ...doc.data() };

          // Check if there is an image associated with this user
          // Check if there is an image associated with this user
          // Check if there is an image associated with this user
          // Check if there is an image associated with this user
          const emailId = userData.email;
          const imageFileName = emailId; // Replace with your naming convention
          const imageRef = storage.ref('files').child(imageFileName);

          imageRef.getDownloadURL()
            .then((url) => {
              userData.imageURL = url;
            })
            .catch((error) => {
              if (error.code === 'storage/object-not-found') {
                // Handle the case where the image file does not exist
                console.log("Image not found for:", emailId);
                userData.imageURL = null; // Set imageURL to null to indicate no screenshot
              } else {
                // Handle other errors
                console.error("Error getting image URL:", error);
                userData.imageURL = null; // Set imageURL to null to indicate an error
              }
            })
            .finally(() => {
              // Push the user data to the array, even if there was an error
              data.push(userData);
              setRegistrationData([...data]); // Update the state with the new data
            });




        });
        setRegistrationData(data);
      });
    }
  }, [loggedIn]);


  const handleLogin = () => {
    // Replace with your actual username and password
    const validUsername = "admin";
    const validPassword = "password";

    if (username === validUsername && password === validPassword) {
      setLoggedIn(true);
      setErrorMessage("");
    } else {
      setLoggedIn(false);
      setErrorMessage("Invalid username or password.");
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "uname",
      },
      {
        Header: "Date of Birth",
        accessor: "dob",
      },
      {
        Header: "Gender",
        accessor: "gender",
      },
      {
        Header: "Email",
        accessor: "collemail",
      },
      {
        Header: "Phone",
        accessor: "phone",
      },
      {
        Header: "SAP ID",
        accessor: "sapId",
      },
      {
        Header: "Course",
        accessor: "course",
      },
      {
        Header: "Department",
        accessor: "department",
      },
      {
        Header: "Degree Start Year",
        accessor: "startyear",
      },
      {
        Header: "Degree End Year",
        accessor: "endyear",
    
      },

      {
        Header: "Current Year",
        accessor: "currentYear",
      },
      {
        Header: "Membership Period",
        accessor: "membershipPeriod",
      },
      {
        Header: "Mode Of Payment",
        accessor: "modeofpay",
      },
      {
        Header: "Sender's Name",
        accessor: "sendname",
      },
      {
        Header: "Transaction ID",
        accessor: "transid",
      },
      {
        Header: "Transaction ScreenShot",
        accessor: "imageURL", // Use the accessor for the imageURL property
        Cell: ({ value }) => {
          return value ? (
            <a href={value} target="_blank" rel="noopener noreferrer">
              Download
            </a>
          ) : (
            "No Screenshot"
          );
        }
      },


    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: registrationData,
    },
    useGlobalFilter,
    useSortBy
  );


  return (

    <div className="container mx-auto text-white p-4">

      {loggedIn ? (
        <>
          <h2 className="text-2xl font-semibold mb-4">Registration Data</h2>
          <input
            type="text"
            placeholder="Search..."
            onChange={(e) => setGlobalFilter(e.target.value)}
            className="mb-4 px-2 py-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
          <div className="flex justify-between mb-4">
            <CSVLink
              data={registrationData}
              headers={columns.map((column) => ({
                label: column.Header,
                key: column.accessor,
              }))}
              filename={"registration_data.csv"}
              className="bg-indigo-500 text-white font-semibold py-2 px-4 rounded hover:bg-indigo-600 focus:outline-none focus:ring focus:ring-indigo-200"
            >
              Export CSV
            </CSVLink>
          </div>
          <div className="overflow-x-auto bg-opacity-50 backdrop-blur-lg backdrop-filter rounded-lg p-4">
            <table {...getTableProps()} className="border-separate min-w-full table-auto  border-4  ">
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()} className=" border-4">
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        className="px-4 py-2 border-2 text-xl"
                      >
                        {column.render("Header")}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? " 🔽"
                              : " 🔼"
                            : ""}
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} className="">
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()} className="px-4 py-2 border-1">
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <div className="max-w-md mx-auto p-4 bg-white rounded-lg shadow-md">
          <h2 className="text-2xl font-semibold mb-4">Admin Login</h2>
          <p className="text-red-500">{errorMessage}</p>
          <div className="mb-4">
            <label
              htmlFor="username"
              className="block text-gray-700 font-medium"
            >
              Username:
              <input
                type="text"
                id="username"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </label>
          </div>
          <div className="mb-4">
            <label
              htmlFor="password"
              className="block text-gray-700 font-medium"
            >
              Password:
              <input
                type="password"
                id="password"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </label>
          </div>
          <button
            onClick={handleLogin}
            className="bg-indigo-500 text-white font-semibold py-2 px-4 rounded hover:bg-indigo-600 focus:outline-none focus:ring focus:ring-indigo-200"
          >
            Login
          </button>
        </div>
      )}
    </div>
  );
};

export default AdminPage;