const data={"info":<>
    <h1 style={{fontSize:"1.8rem",fontWeight:"600",textAlign:"left"}} st>Tuba Khan</h1> 
    <div className="d-flex justify-content-center align-items-center pratha" style={{gap:"5rem"}} >
    
    <div className="right-box-info">
    
    <br/>
    <ul className="pra-det">
    <li>I am Tuba Khan, a pre-final year Engineering student doing major in Artificial Intelligence.</li> 
    <li>Associate Design & VFX Head, UPES CSI.</li>
    <li>I am a Web Developer, UI/UX Designer, Android Developer and a graphic designer with a strong background in supporting development projects.</li>
    <li> Successful at using programming languages to create quality work, I am independent when working solo and an excellent team player when communicating with others.</li>
    </ul>
    </div>
    </div>
    </>
     }
    
     export default data;