import React, { useRef, useState } from "react";
import "./background.css";
import bg from "../Images/bg-video.mp4";

import Modal from 'react-responsive-modal'
import '../New Register/NewRegister.css'

const VideoBackground = () => {
  const videoRef = useRef(null);

  const playVideo = () => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  };


  // Event listener to play video when the 'l' key is pressed
  window.addEventListener("keydown", (e) => {
    if (e.key === "l") {
      playVideo();
    }
  });





  return (
    <>
    
    <div className="video-background">
      <video
        ref={videoRef}
        loop
        muted
        autoPlay={true}
        preload={true}
        data-preload="true"
        playsInline
        style={{ position: "absolute" }}

      >
        <source src={bg} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="content w-100" style={{ zIndex: "1010" }}>
        <div
          className="container-fluid csi-heading"
          style={{ textAlign: "center" }}
        >
          <h1>COMPUTER SOCIETY OF INDIA</h1>
          <span className="mt-2 sub-heading">
            CREATE &nbsp;&nbsp;|&nbsp;&nbsp; SHARE &nbsp;&nbsp;|&nbsp;&nbsp;
            INNOVATE

          </span>
          {/* Button to open Register Now Popup */}
        </div>

      </div>

    </div>

 
</>
  );
};

export default VideoBackground;
