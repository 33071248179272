import logo from "./logo.svg";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Home from "./Pages/Home/Home.jsx";
import About from "./Pages/About Us/About";
import NavBar from "./Components/NavBar/Navbar.js";
import Events from "./Pages/Events/Events";
import ContactForm from "./Pages/Contact";
import L_Sidebar from "./Components/L-Sidebar/L_Sidebar";
import Team from "./Pages/Team/Team";
import NewFooter from "./Components/New Footer/NewFooter";
import Congratulation from "./Components/Congratulations/Congratulation";
import AdminPortal from "./Pages/Admin/Admin";

function App() {

  // Check if the current route is the admin page
  const isAdminPage = "True";

  return (
    <Router>
      <div className="blur-background"></div>
      <div className="website">
        {/* Conditionally render the NavBar and L_Sidebar based on isAdminPage */}
       <NavBar />
        <L_Sidebar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/events" element={<Events />} />
          <Route path="/contact" element={<ContactForm />} />
          <Route path="/team" element={<Team />} />
          <Route path="/congratulations" element={<Congratulation />} />
        </Routes>
      </div>
      <Routes>
        <Route path="/admin" element={<AdminPortal />} />
      </Routes>
      <NewFooter />
    </Router>
  );
}

export default App;
