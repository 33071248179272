import React from 'react'
import './Congratulation.css'
export default function Congratulation() {
  return (
    <div className='final-cl'>
    <p className=''>Congratualtions!!</p>
    <p className='text-op'>Welcome To</p>
    <p className='text-op'>CSI Family</p>
    </div>
  )
}
