const data = [
  {
    img: require('./techHunt.png'),
    eventname: 'Tech-Hunt',
    evantDate: '8th November 2023',
    description: 'A Halloween “Tech-Hunt”! This was not just an event, it was a journey into the cryptic world of algorithms, brought to you by the UPES-CSI student chapter. This thrilling event unfolded on the afternoon of a radiant Wednesday, November 8 within the college premises. Our participants embarked on a thrilling quest, their mastery of algorithms acting as their reliable guide in this coding expedition. The eerie whispers of Halloween echoed around, but the true phantoms were the complex problems that lay ahead. Amidst this spectral symphony of algorithms and apparitions, a treasure hunt was woven in, turning the event into a grand adventure. Each clue deciphered, each treasure found, added a new twist to this Tech-Hunt. In the end, every participant proved that in the world of technology, every day can be a thrilling Halloween.',
    color: 'blue',
    imgo: [
      require('./dataJs/techHunt-23/img_2.jpg'),
      require('./dataJs/techHunt-23/img_9.png'),
      require('./dataJs/techHunt-23/img_4.png'),
      require('./dataJs/techHunt-23/img_5.png'),
      require('./dataJs/techHunt-23/img_1.jpg'),
      require('./dataJs/techHunt-23/img_3.jpg'),
      require('./dataJs/techHunt-23/img_6.png'),
      require('./dataJs/techHunt-23/img_7.png'),
      require('./dataJs/techHunt-23/img_8.png'),
      require('./dataJs/techHunt-23/img_10.png')
    ]
  },
  {
    img: require("./csr-momento-final.png"),
    eventname: "CSR",
    evantDate: "26th August 2023",
    description:
      "It is an event where the committee visits orphanages or schools to actively engage the underprivileged in various fun and interesting activities. These events foster a sense of belonging and empowerment amongst the committee members to contribute meaningfully to the society and its upliftment.",
    color: "blue",
    imgo: [
      require("./dataJs/CSR/IMG_6106.jpg"),
      require("./dataJs/CSR/IMG_6140.jpg"),
      require("./dataJs/CSR/IMG_9227.jpg"),
      require("./dataJs/CSR/IMG_9300.jpg"),
      require("./dataJs/CSR/_MG_8026.JPG"),
      require("./dataJs/CSR/_MG_8193.JPG"),
      require("./dataJs/CSR/_MG_8194.JPG"),
      require("./dataJs/CSR/_MG_8815.JPG"),
      require("./dataJs/CSR/_MG_9152.JPG"),
      require("./dataJs/CSR/IMG_6396.JPG"),
    ],
  },
  {
    img: require("../Images/WhatsApp Image 2023-09-03 at 9.24.53 PM.jpeg"),
    eventname: "Arena 2.0",
    evantDate: "23th July 2023",
    description:
      "UPES-CSI organised a dynamic gaming event which featured two of the most popular games amongst teenagers these days i.e. Valorant and BGMI. The BGMI Tournament, tested the participants' battle royale capabilities in intense competitive showdowns. The Valorant Tournament brought the strategies of FPS gameplay and battles, as the game is known for its vibrant character design, showcasing intense battles of skills and teamwork.",
    color: "red",
    imgo: [
      require("./dataJs/Arena/2022-07-16 (1).png"),
      require("./dataJs/Arena/2022-07-16 (4).png"),
      require("./dataJs/Arena/Screenshot (350).png"),
      require("./dataJs/Arena/V1.png"),
      require("./dataJs/Arena/WhatsApp Image 2022-07-23 at 2.22.14 AM.jpeg"),
      require("./dataJs/Arena/WhatsApp Image 2022-07-23 at 2.25.18 AM.jpeg"),
      require("./dataJs/Arena/WhatsApp Image 2022-07-23 at 2.25.19 AM (2).jpeg"),
      require("./dataJs/Arena/WhatsApp Image 2022-07-23 at 2.25.32 AM.jpeg"),
    ],
  },
  {
    img: require("./image_6aec66bb-3be1-4c02-ae18-02a6c313df9920230905_230026.jpg"),
    eventname: "Yugmak 2023",
    evantDate: "13-15th April 2023",
    description:
      "UPES-CSI organised a dynamic gaming event which featured two of the most popular games amongst teenagers these days i.e. Valorant and BGMI. The BGMI Tournament, tested the participants' battle royale capabilities in intense competitive showdowns. The Valorant Tournament brought the strategies of FPS gameplay and battles, as the game is known for its vibrant character design, showcasing intense battles of skills and teamwork.",
    color: "red",
    imgo: [
      require('./dataJs/Yugmak-23/IMG_9133.JPG'),
      require('./dataJs/Yugmak-23/IMG_9138.JPG'),
      require('./dataJs/Yugmak-23/IMG_9595.JPG'),
      require('./dataJs/Yugmak-23/IMG_9624.JPG'),
      require('./dataJs/Yugmak-23/IMG_9747.JPG'),
      require('./dataJs/Yugmak-23/IMG_9833.JPG'),
      require('./dataJs/Yugmak-23/IMG_9842.JPG'),
      require('./dataJs/Yugmak-23/IMG_9882.JPG'),
      require('./dataJs/Yugmak-23/IMG_9904.JPG'),
      require('./dataJs/Yugmak-23/DSC_0062.JPG'),
      require('./dataJs/Yugmak-23/DSC_0065.JPG'),
      require('./dataJs/Yugmak-23/DSC_0077.JPG'),
      require('./dataJs/Yugmak-23/DSC_0087.JPG'),
      require('./dataJs/Yugmak-23/DSC_0104.JPG'),
      require('./dataJs/Yugmak-23/DSC_0110.JPG'),
      require('./dataJs/Yugmak-23/DSC_0142.JPG'),
      require('./dataJs/Yugmak-23/DSC_0197.JPG'),
      require('./dataJs/Yugmak-23/DSC_0216.JPG'),
      require('./dataJs/Yugmak-23/DSC_0269.JPG'),
      require('./dataJs/Yugmak-23/DSC_0289.JPG'),
      require('./dataJs/Yugmak-23/DSC_0355.JPG'),
      require('./dataJs/Yugmak-23/IMG_0115.JPG'),
      require('./dataJs/Yugmak-23/IMG_0138.JPG'),
      require('./dataJs/Yugmak-23/IMG_0296.JPG'),
      require('./dataJs/Yugmak-23/IMG_0562.JPG'),
      require('./dataJs/Yugmak-23/IMG_0579.JPG'),
      require('./dataJs/Yugmak-23/IMG_0652.JPG'),
      require('./dataJs/Yugmak-23/IMG_0746.JPG'),
      require('./dataJs/Yugmak-23/IMG_0747.JPG'),
      require('./dataJs/Yugmak-23/IMG_2127.JPG'),
            require('./dataJs/Yugmak-23/20230411_131943.jpg'),

      require('./dataJs/Yugmak-23/20230411_132556.jpg')
,
      require('./dataJs/Yugmak-23/Copy of IMG_4125.JPG'),

      require('./dataJs/Yugmak-23/Copy of IMG_9904.JPG'),

      require('./dataJs/Yugmak-23/DSC_0065 (1).JPG')


    ],
  },
  {
    img: require("./Hackathon 7.0.png"),
    eventname: "Hackathon 7.0",
    evantDate: "17-19th February 2023",
    description:
      "UPES-CSI brings you the biggest code fest with 48 hours of intense coding and amazing prizes. Hackathon has been our growing legacy for the past seven years. This event will challenge you to push your boundaries as only few can emerge victorious in this ultimate tech-fest.",
    color: "blue",
    imgo: [
      require("./dataJs/hackathon7.0/img1 (1).png"),
      require("./dataJs/hackathon7.0/img1 (2).png"),
      require("./dataJs/hackathon7.0/img1 (3).png"),
      require("./dataJs/hackathon7.0/img1 (4).png"),
      require("./dataJs/hackathon7.0/img1 (5).png"),
      require("./dataJs/hackathon7.0/img1 (6).png"),
      require("./dataJs/hackathon7.0/img1 (7).png"),

      require("./dataJs/hackathon7.0/img1 (1).JPG"),
      require("./dataJs/hackathon7.0/img1 (2).JPG"),
      require("./dataJs/hackathon7.0/img1 (3).JPG"),
      require("./dataJs/hackathon7.0/img1 (4).JPG"),
      require("./dataJs/hackathon7.0/img1 (5).JPG"),
      require("./dataJs/hackathon7.0/img1 (6).JPG"),
      require("./dataJs/hackathon7.0/img1 (7).JPG"),
      require("./dataJs/hackathon7.0/img1 (8).JPG"),
      require("./dataJs/hackathon7.0/img1 (9).JPG"),
      require("./dataJs/hackathon7.0/img1 (10).JPG"),
      require("./dataJs/hackathon7.0/img1 (11).JPG"),
      require("./dataJs/hackathon7.0/img1 (12).JPG"),
    ],
  },
  
  
  // {
  //     "img": require('../Images/WhatsApp Image 2023-09-03 at 9.24.53 PM.jpeg'),
  //     "eventname":"Valorant",
  //     "evantDate":"25th May 2023",
  //     "description":"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  //     "color":"red"
  // },
  // {
  //     "img": require('../Images/WhatsApp Image 2023-09-03 at 9.24.53 PM.jpeg'),
  //     "eventname":"Valorant",
  //     "evantDate":"25th May 2023",
  //     "description":"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  //     "color":"blue"
  // },
  // {
  //     "img": require('../Images/WhatsApp Image 2023-09-03 at 9.24.53 PM.jpeg'),
  //     "eventname":"Valorant",
  //     "evantDate":"25th May 2023",
  //     "description":"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  //     "color":"red"
  // },
  // {
  //     "img": require('../Images/WhatsApp Image 2023-09-03 at 9.24.53 PM.jpeg'),
  //     "eventname":"Valorant",
  //     "evantDate":"25th May 2023",
  //     "description":"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  //     "color":"blue"
  // },
  // {
  //     "img": require('../Images/WhatsApp Image 2023-09-03 at 9.24.53 PM.jpeg'),
  //     "eventname":"Valorant",
  //     "evantDate":"25th May 2023",
  //     "description":"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  //     "color":"red"
  // }
];


module.exports=data