import React from "react";

// import Gevents from '../../Components/gallery-event/galleryEvents'
import Year from "../../Components/new Event/Year.jsx";

const Event = () => {

  return (
    <div className="event">
      <Year />
    </div>
  );
};

export default Event;
