const data = {
    info: (
        <>
            <h1 style={{ fontSize: "1.5rem", fontWeight: "600" }}>Shubhi Sharma</h1>
            <br></br>
            <ul className="aayu-det">
                <li>I am Shubhi Sharma, Student Branch Coordinator at UPES-CSI.</li>
                {/* <li>Joint Secretary, UPES-CSI</li> */}
            </ul>
        </>
    ),
};

export default data;
