import React, { useEffect, useRef, useState } from 'react';
import './style.css'; // Import the CSS file
import img1 from './aayushmanGussain.png';
import img2 from './parthNautiyal.png';
import img3 from './sahajSomvanshi.png';
import img4 from './dakshiAggarwal.png';
import img5 from './harshRaturi.png';
import img6 from './ishikaPunhani.png';
import img7 from './rimjhimGupta.png';
import img8 from './prathamKandari.png';
import img9 from './VamikaMahajan.png';
import img10 from './abhipriyatyagi.png';
import img11 from './devashishsharma.png';
import img12 from './aryandeopa.png';

import Heading from '../Heading/Heading';
const testimonials = [
  {
    name: "Abhipriya Tyagi",
    job: "President",
    image: img10,
    testimonial:
      "I began my journey with UPES-CSI as a core committee member in my second year, then moved on to become M&R Head, and now serve as President. I can confidently say that this chapter has been instrumental in shaping my personality. Coming from an introverted background, CSI has helped me break out of my shell and take on challenges with enthusiasm. Beyond the technical skills and leadership experience, what makes this journey truly special are the people I've met along the way. I've been fortunate to have the guidance of wonderful seniors, the support of incredible colleagues, and the enthusiasm of talented juniors. For me, CSI is not just an organization; it's a family that I am proud to be a part of.",
  },
  {
    name: "Aayushman Gusain",
    job: "Vice President",
    image: img1,
    testimonial:
      "What is CSI to me? It's the cornerstone of my academic and extracurricular growth, the safety net for my endeavors, and most importantly, a second family to me. The one who has taught me to find joy even on the most mundane days and to be the reason someone else smiles another day. With supporting seniors, caring colleagues, and loving juniors, I feel i don't deserve to be sad because of so much to be thankful about.",
  },
  {
    name: "Devashish Sharma",
    job: "General Secretary",
    image: img11,
    testimonial:
      " It's been three years since I began my journey as a core committee member with UPES-CSI. In my first year, I served on the Design & VFX Committee. In my second year, feeling a lack of social connection, I also joined the Public Relations Committee. My hard work paid off in my third year when I was given the responsibility of Internal Operations Head. And now, I am serving as the General Secretary for UPES-CSI. Throughout my time with CSI, it has never felt like a burden or as if I were working with strangers. The environment in CSI is incredibly welcoming. UPES-CSI will always hold a special place in my heart as my second home, and I am excited to see where this journey will take me in the years to come.",
  },
  {
    name: "Parth Nautiyal",
    job: "Secretary",
    image: img2,
    testimonial:
      "Joining UPES-CSI marked the beginning of a life-changing adventure. From leadership development to fostering lifelong friendships, this journey has been impactful. I've learned teamwork and problem-solving, setting the stage for my future. The bonds formed with fellow members extend far beyond chapter activities, providing a long lasting social network. Personal growth has been immense, pushing me beyond my comfort zone. As I look ahead, I'm excited about the opportunities this student chapter has prepared me for in the future.",
  },
  {
    name: "Sahaj Somvanshi",
    job: "Treasurer",
    image: img3,
    testimonial:
      "I really find it difficult to express CSI in light of the fact that I have numerous memories associated with it. From an introverted Public Relations Core Committee Member to the financial head and treasurer of CSI was a long excursion. In this excursion CSI helped, it gave me an identity within the college, assisted me with finding a family who is in every case together in a wide range of troubles showed me scholastically, and inwardly, and sustained me into an extraordinary individual. Without the almighty, who guided me in the right direction, my seniors, who assisted me in making decisions, my colleagues, who encouraged and supported me in all sort of ways, and my juniors, who are my family and everything and never stopped surprising us, none of this would have been possible.",
  },
  {
    name: "Aryan Deopa",
    job: "Director",
    image: img12,
    testimonial:
      " I joined UPES-CSI in my first year with no expectations at all. I met new people, looked up to some people and thought I want to be in their place and want people to look up to me. Honestly speaking I never thought of myself as good in any skills so I found myself trying everything I could get my hands on. Not only learning from my seniors but also my colleagues and juniors too. As an introvert it was really hard to talk to people at first but slowly slowly without even realising, UPES-CSI gave me so many people and memories to treasure. It really gave me so much exposure to many experiences and gave me confidence to showcase my work in front of the world. Now when anyone asks me about what I did in these past years of my college, the first thing I show them is UPES-CSI. I cannot imagine my college life being so fun and fulfilling without UPES-CSI being a part of it",
  },
  {
    name: "Dakshi Aggarwal",
    job: "Director",
    image: img4,
    testimonial:
      "My three-year journey with the UPES-CSI Student Chapter has been an amazing adventure. I began as a committee member and have now taken on the role of director. Reflecting on these years fills me with gratitude for the personal growth, meaningful friendships, and countless opportunities UPES-CSI has offered to me. Looking back, I remember being a newcomer with little knowledge about student chapters, and today, I find myself in a position of responsibility, actively participating in decision-making and guiding juniors while being mentored by my seniors. This transformation has profoundly impacted my college experience. I struggle to put into words just how thankful and proud I am to be part of the CSI family. I genuinely wish nothing but the best for the chapter in all its future endeavors.",
  },
  {
    name: "Harsh Raturi",
    job: "Director",
    image: img5,
    testimonial:
      "UPES CSI means a lot more to me than just a student chapter. It's like a family that has been with me throughout my time in university. Every memory I've made here is very special, and I want to thank my fellow students and teachers for making my university experience so great.",
  },
  {
    name: "Ishika Punhani",
    job: "Director",
    image: img6,
    testimonial:
      "I find it challenging to put into words what CSI means to me as I have so many beautiful memories from my time working over the years. For me, it is more than simply a student chapter; it represents a feeling and, more significantly, a family that has helped me develop as a person and learn further.",
  },
  {
    name: "Rimjhim Gupta",
    job: "Working Committee Head",
    image: img7,
    testimonial:
      "I joined CSI in the first year of college, and my time with the chapter has been a heartfelt journey. From organising events to witnessing the brilliance of our team, every moment has been memorable. CSI is more than just a chapter to me, it has always been like a family. I carry each experience with me and now look forward to learning new things.",
  },
  {
    name: "Pratham Kandari",
    job: "Technical Head",
    image: img8,
    testimonial:
      "I joined UPES CSI a year ago in my second year, and it's been a great journey. I've made friends and had fun organizing events. Now, I'm excited to lead and learn more as the Head and a member. I'm looking forward to creating more wonderful memories and contributing to the chapter's success in the future.",
  },
  {
    name: "Vamika Mahajan",
    job: "Design & VFX Head",
    image: img9,
    testimonial:
      "UPES-CSI, to me, in more than just a student chapter, it's a family. It is an integral part of my college life. UPES-CSI has been instrumental in my personal and professional growth. It has not only made me a better individual, a more aware, considerate, and confident human being. I have created memories of a lifetime, and it has been a remarkable journey. Today, I am proud to be the Design and VFX head of UPES-CSI. Lastly, I just want to say that I have some amazing college memories, and most of them are from CSI.",
  },
];

const TestimonialSlider = () => {

  const contat = useRef()
  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide((currentSlide + 1) % testimonials.length);
  };

  const prevSlide = () => {
    setCurrentSlide((currentSlide - 1 + testimonials.length) % testimonials.length);
  };

  useEffect(() => {
    displayTestimonial();
  });

  const displayTestimonial = () => {
    if (contat.current) {
      const testimonialContainer = contat.current;

      testimonialContainer.innerHTML = `
      <p>${testimonials[currentSlide].testimonial}</p>
      <img src=${testimonials[currentSlide].image} alt="${testimonials[currentSlide].name}">
      <h3>${testimonials[currentSlide].name}</h3>
      <h6>${testimonials[currentSlide].job}</h6>
    `;
    }
  };

  return (
    <div className='mt-5 pt-5'>
      <Heading head="Our Stories" size="50px"></Heading>
      <div className="wrapper2 mt-4">

        <div className="testimonial-container" id="testimonial-container" ref={contat}></div>
        <button id="prev" onClick={prevSlide}>&lt;</button>
        <button id="next" onClick={nextSlide}>&gt;</button>
      </div>
    </div>
  );
};

export default TestimonialSlider;
