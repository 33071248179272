const data = {
    "info": <><h1 style={{ fontSize: "1.5rem", fontWeight: "600" }}>Sugandha Sharma</h1>
        <br></br>
        <ul className="aayu-det">
            <li>
            An open-source technology enthusiast who supports and promotes “technology for all“
            </li>
            <li>
            A guest speaker at various events promoting Artificial Intelligence and its applications.
            </li>
            <li>
            State Vice President-Uttarakhand Startup Council, WICCI and State Representative of Uttarakhand for Heal India Movement.
            </li>
            <li>
            A recipient of "Iconic Women Award" by Women Economic Forum in 2022 at the 84th Global Edition.
A recipient of “ Super Woman Award 2021” by Rotary Club, Uttarakhand held on International Women’s Day ‘2021.
            </li>
        </ul>
    </>
}

export default data;