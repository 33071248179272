const data = {
  info: (
    <>
      <h1 style={{ fontSize: "1.5rem", fontWeight: "600" }}>Keshav Garg</h1>
      <br></br>
      <ul className="aayu-det">
        <li>I'm Keshav Garg , a pre-final year student B.Tech CSE specialisation Cyber Security and Forensics.</li>
        <li>Associate Treasurer, UPES-CSI</li>
        <li>I have been part several organising committee for several UPES events and hackathon.</li>
      </ul>
    </>
  ),
};

export default data;
