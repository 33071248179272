import React, { useRef, useState } from 'react'
import logo from "../Images/logo1.png";
import { NavLink } from 'react-router-dom';
import './style.css'
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import qr from '../Images/payment_qr.png'
import { db, storage } from '../../firebase_config';




export default function () {



  const box = useRef();
  const inBox = useRef();

  const [boxStyle, setBoxStyle] = useState({ width: "80px" });
  const [smallBoxStyle, setSmallBoxStyle] = useState({ transform: "translateX(0%)" })
  const [bigBoxStyle, setBigBoxStyle] = useState({ transform: "translateX(-50vw)" })
  const move = () => {
    setBoxStyle({ width: "max-content" });
    setSmallBoxStyle({ transform: "translateX(-100%)", position: "absolute" })
    setBigBoxStyle({ transform: "translateX(0%)" })
  }

  const backMove = () => {
    setBoxStyle({ width: "80px" });
    setSmallBoxStyle({ transform: "translateX(0%)", position: "relative" })
    setBigBoxStyle({ transform: "translateX(-50vw)" })


  }
  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const [open22, setOpen22] = useState(false);

  const onOpenModal22 = () => setOpen22(true);
  const onCloseModal22 = () => setOpen22(false);

  const [pricei, setPrice] = useState("₹375");

  const [email, setEmail] = useState("");
  const resetForm = () => {
    setPrice("₹375");
    setEmail("");
    // Reset other form fields as needed
    document.getElementById('name').value = "";
    document.getElementById('collemail').value = "";
    document.getElementById('dob').value = "";
    document.getElementById('gender').value = "";
    document.getElementById('phone').value = "";
    document.getElementById('sapID').value = "";
    document.getElementById('course').value = "";
    document.getElementById('department').value = "";
    document.getElementById('startyear').value = "";
    document.getElementById('endyear').value = "";
    document.getElementById('current').value = "";
    document.getElementById('membership').value = "";
    document.getElementById('modeofpay').value = "";
    document.getElementById('sendname').value = "";
    document.getElementById('transid').value = "";
    document.getElementById('file-qr').value = "";
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const file = document.getElementById('file-qr').files[0];

    if (file) {

      const newFileName = email;


      const fileRef = storage.ref('files').child(newFileName);

      fileRef.put(file)
        .then(() => {
          return fileRef.getDownloadURL();
        })
        .then((fileURL) => {
          data.fileURL = fileURL;
        })
        .catch(error => {
          console.error("Error uploading file:", error);
          alert("Error uploading file");
        });
    }


    const uname = document.getElementById('name').value;
    const collemail = document.getElementById('collemail').value;
    const dob = document.getElementById('dob').value;
    const gender = document.getElementById('gender').value;
    const phone = document.getElementById('phone').value;
    const sapId = document.getElementById('sapID').value;
    const course = document.getElementById('course').value;
    const department = document.getElementById('department').value;
    const startyear = document.getElementById('startyear').value;
    const endyear = document.getElementById('endyear').value;
    const currentYear = document.getElementById('current').value;
    const membershipPeriod = document.getElementById('membership').value;
    const modeofpay = document.getElementById('modeofpay').value;
    const sendname = document.getElementById('sendname').value;
    const transid = document.getElementById('transid').value;

    const data = {
      uname: uname,
      dob: dob,
      gender: gender,
      phone: phone,
      sapId: sapId,
      course: course,
      department: department,
      startyear: startyear,
      endyear: endyear,
      currentYear: currentYear,
      membershipPeriod: membershipPeriod,
      modeofpay: modeofpay,
      sendname: sendname,
      transid: transid,
      email: email,
      collemail: collemail
    

    }


    db.collection("Members")
      .doc(email)
      .set(data)
      .then(() => {
        alert("Your response has been recorded");
        resetForm();
      })
      .catch((error) => {
        alert(error.message);
      });

  };


  function price() {
    if (document.getElementById('membership').value !== null) {
      if (document.getElementById('membership').value === "1Yr") {
        setPrice("₹375");
      }
      else
        if (document.getElementById('membership').value === "2Yr") {
          setPrice("₹575");
        }
        else
          if (document.getElementById('membership').value === "3Yr") {
            setPrice("₹750");
          }
          else
            if (document.getElementById('membership').value === "4Yr") {
              setPrice("₹800");
            }
    }
  }


  return (
    <>
      <button className='ham-btn' onClick={onOpenModal}><i class="fa-solid fa-bars fa-2xl"></i></button>
      <div className={`navbar-main container`} onMouseOver={move} style={boxStyle} onMouseLeave={backMove}>

        <div className='nav-vert-out' style={smallBoxStyle}>

          <div className='navbar-vertical' >
            <div className='logo-img'>
              <img src={logo} className='logo'></img>
            </div>
            <span className='rotate-text-container'><h1 className='rotate-text' style={{ fontSize: '25px' }}>UPES-CSI STUDENT CHAPTER</h1></span>
          </div>
        </div>
        <div className={`navbar-inner d-flex flex-column align-items-center justify-content-center`} style={bigBoxStyle}>
          <div className='logo-img'>
            <img src={logo} className='logo'></img>
          </div>

          <div className='list-items'>
            <ul className='container d-flex flex-column align-items-start gap-1 ul-contain'>
              <li><NavLink to="/" onClick={backMove} className={({ isActive }) => (isActive ? 'active' : 'inactive')}><i className="fa-solid fa-house"></i><span class="list-item-text">Home</span></NavLink></li>
              <li><NavLink to="/about" onClick={backMove} className={({ isActive }) => (isActive ? 'active' : 'inactive')}><i class="fa-solid fa-user"></i><span class="list-item-text">About Us</span></NavLink></li>
              <li><NavLink to="/events" onClick={backMove} className={({ isActive }) => (isActive ? 'active' : 'inactive')}><i class="fa-solid fa-calendar-days"></i><span class="list-item-text">Events</span></NavLink></li>
              <li><NavLink to="/team" onClick={backMove} className={({ isActive }) => (isActive ? 'active' : 'inactive')}><i class="fa-solid fa-people-group"></i><span class="list-item-text">Team</span></NavLink></li>
              <li><NavLink to="/contact" onClick={backMove} className={({ isActive }) => (isActive ? 'active' : 'inactive')}><i class="fa-solid fa-phone"></i><span class="list-item-text">Contact Us</span></NavLink></li>
              {/* <li><a className="in-btn" style={{ cursor: "pointer" }} onClick={onOpenModal22}>Register Now</a></li> */}
            </ul>
          </div>
        </div>
      </div>

      <Modal open={open} onClose={onCloseModal} left blockScroll={false} classNames={{
        overlay: 'customOverlay',
        modal: 'customModal',
        root: "pk"
      }}>
        <div className={`navbar-inner d-flex flex-column align-items-center justify-content-center mobile-nav`}>
          <div className='logo-img'>
            <img src={logo} className='logo'></img>
          </div>

          <div className='list-items'>
            <ul className='container d-flex flex-column align-items-start gap-1 ul-contain'>
              <li><NavLink to="/" onClick={onCloseModal} className={({ isActive }) => (isActive ? 'active' : 'inactive')}><i className="fa-solid fa-house"></i>Home</NavLink></li>
              <li><NavLink to="/about" onClick={onCloseModal} className={({ isActive }) => (isActive ? 'active' : 'inactive')}><i class="fa-solid fa-user"></i>About Us</NavLink></li>
              <li><NavLink to="/events" onClick={onCloseModal} className={({ isActive }) => (isActive ? 'active' : 'inactive')}><i class="fa-solid fa-calendar-days"></i>Events</NavLink></li>
              <li><NavLink to="/team" onClick={onCloseModal} className={({ isActive }) => (isActive ? 'active' : 'inactive')}><i class="fa-solid fa-people-group"></i>Team</NavLink></li>
              <li><NavLink to="/contact" onClick={onCloseModal} className={({ isActive }) => (isActive ? 'active' : 'inactive')}><i class="fa-solid fa-phone"></i>Contact Us</NavLink></li>
              <li><a className="inactive in-btn" style={{ cursor: "pointer" }} onClick={onOpenModal22}>Register Now</a></li>

            </ul>
          </div>
        </div>
      </Modal>

      <Modal open={open22} onClose={onCloseModal22} center blockScroll={true} classNames={{
        overlay: 'customOverlay44',
        modal: 'customModal44',
        root: "pk44"
      }}>
        <h1 className='form-heading'>Registration Form</h1>
        <div className="form-container container d-flex align-items-start justify-content-center">
          <div className="iiimmo">
            <div className="d-flex align-items-center justify-content-center align-items-center flex-column">
              <div className="con-img">
                <img src={qr} alt="" />
              </div>
              <form action="" className='new-form2'>

              </form>
            </div>
          </div>
          <form className='new-form' onSubmit={handleSubmit}>
            <table className='new-table'>
              {/* <tr>
                <td colSpan={2}>
                  <div className='price-csi'>  Total Amount: <span className="price">{pricei}</span></div>
                </td>
              </tr> */}
              <tr>
                <td colspan="2">
                  {/* <label for="name">Name:</label> */}
                  <input type="text" id="name" name="name" placeholder='Enter your Name' required />
                </td>
              </tr>

              <tr>
                <td colspan="1" style={{ paddingRight: "5px" }}>
                  {/* <label for="dob">DOB:</label> */}

                  <input type="date" id="dob" name="dob" placeholder="DOB" pattern="\d{4}-\d{2}-\d{2}" required />

                </td>
                <td colspan="1" style={{ paddingLeft: "5px" }}>
                  {/* <label for="gender">Gender:</label> */}
                  <select id="gender" name="gender" required placeholder='Gender'>
                    <option value="">Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </select>
                </td>
              </tr>

              <tr>
                <td colspan="1" style={{ paddingRight: "5px" }}>
                  {/* <label for="email">Email:</label> */}
                  <input type="email" id="email" name="email" placeholder='Primary Email ID' value={email}
                    onChange={(e) => setEmail(e.target.value)} required />
                </td>

                <td colspan="1" style={{ paddingLeft: "5px" }}>
                  {/* <label for="phone">Phone:</label> */}
                  <input type="tel" id="phone" name="phone" placeholder='Phone' required />
                </td>
              </tr>

              <tr>
              <td colspan="1" style={{ paddingLeft: "5px" }}>
                  {/* <label for="department">Degree:</label> */}
                  <input type="text" id="collemail" name="collemail" placeholder='College Email ID' required />
                </td>

                <td colspan="1" style={{ paddingLeft: "5px" }}>
                  {/* <label for="phone">Phone:</label> */}
                  <input type="tel" id="sapID" name="sapID" placeholder='SAP ID' required />
                </td>
              </tr>

              

              <tr>
                <td colspan="1" style={{ paddingRight: "5px" }}>
                  {/* <label for="course">Course:</label> */}
                  <input type="text" id="course" name="course" placeholder='Course' required />
                </td>
                <td colspan="1" style={{ paddingLeft: "5px" }}>
                  {/* <label for="department">Degree:</label> */}
                  <input type="text" id="department" name="department" placeholder='Department' required />
                </td>
              </tr>



              <tr>
                <td colspan="1" style={{ paddingRight: "5px" }}>
                  {/* <label for="course">Course:</label> */}
                  <input type="text" id="startyear" name="startyear" placeholder='UG/PG Starting Year' required />
                </td>
                <td colspan="1" style={{ paddingLeft: "5px" }}>
                  {/* <label for="department">Degree:</label> */}
                  <input type="text" id="endyear" name="endyear" placeholder='UG/PG Ending Year' required />
                </td>
              </tr>

              {/* <tr>
    <td colspan="1" style={{paddingRight:"5px"}}>
      <label for="degreestart">Degree Start Year:</label>
      <input type="number" id="degreestart" name="degreestart" placeholder='Degree Start Year' required/>
    </td>
    <td colspan="1" style={{paddingLeft:"5px"}}>
      <label for="degreeend">Degree End Year:</label>
      <input type="number" id="degreeend" name="degreeend" placeholder='Degree End Year' required/>
    </td>
  </tr> */}

              <tr>
                <td colspan="1" style={{ paddingRight: "5px" }}>
                  {/* <label for="gender">Gender:</label> */}
                  <select id="current" name="current" required placeholder='current'>
                    <option value="">Current Year</option>
                    <option value="1year">1st Year</option>
                    <option value="2year">2nd Year</option>
                    <option value="3year">3rd Year</option>
                    <option value="4year">4th Year</option>
                  </select>
                </td>
                <td colspan="1" style={{ paddingLeft: "5px" }}>
                  {/* <label for="gender">Gender:</label> */}
                  <select id="membership" name="membership" required placeholder='membership' onChange={() => {
                    price(); // call price function on change
                  }}>
                    <option value="">Membership Period</option>
                    <option value="1Yr" >1 Year (Rs. 375)</option>
                    <option value="2Yr" >2 Years (Rs. 575)</option>
                    <option value="3Yr" >3 Years (Rs. 750)</option>
                    <option value="4Yr" >4 Years (Rs. 800)</option>
                  </select>
                </td>
              </tr>

              <tr>
              <td colspan="2" style={{ paddingLeft: "5px" }}>
                  {/* <label for="gender">Gender:</label> */}
                  <select id="modeofpay" name="modeofpay" required placeholder='Mode of Payment'>
                    <option value="">Mode of Payment</option>
                    <option value="gpay">Google Pay</option>
                    <option value="paytm">Paytm</option>
                    <option value="phonepay">Phone Pay</option>
                    <option value="cash">Cash</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td colspan="1" style={{ paddingRight: "5px" }}>
                  {/* <label for="course">Course:</label> */}
                  <input type="text" id="sendname" name="sendname" placeholder='Sender Name' required />
                </td>
                <td colspan="1" style={{ paddingLeft: "5px" }}>
                  {/* <label for="department">Degree:</label> */}
                  <input type="text" id="transid" name="transid" placeholder='Transaction ID' required />
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <input type="file" name="file-qr" id="file-qr" required />
                </td>
              </tr>

              <tr>
                <td colspan="2">
                  <input type="submit" id="submit-button" className='file-sub' value="Submit" />
                </td>
              </tr>

            </table>
          </form>
        </div>
      </Modal>
    </>
  )
}
