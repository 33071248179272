import React from "react";
import "./NewFooter.css";
import {
  FaInstagram,
  FaTwitter,
  FaFacebook,
  FaLinkedin,
  FaYoutube,
} from "react-icons/fa";

export default function NewFooter() {
  return (
    <footer
      className="container-fluid mt-5 py-4"
      style={{ backgroundColor: "#2b3349" }}
    >
      <div className="d-flex justify-content-center align-items-center flex-column gap-4 new-footer-outer">
        <div className="d-flex justify-content-center align-items-center gap-4 logos-group">
          <a
            href="https://instagram.com/upescsi?igshid=MzRlODBiNWFlZA=="
            target="_blank"
          >
            <FaInstagram
              className="social-link white, w-7 h-auto"
              color="white"
            />
          </a>
          <a
            href=" https://twitter.com/upescsi?t=1fOIJJfJp1H_R1hsg9bmbQ&s=08"
            target="_blank"
          >
            <i
              className="fa-brands fa-x-twitter social-link "
              style={{ color: "white", fontSize: "1.75rem" }}
            ></i>
            {/* <FaTwitter className="social-link white, w-7 h-auto" color="white" /> */}
          </a>
          <a href="https://m.facebook.com/upescsi" target="_blank">
            <FaFacebook
              className="social-link white, w-7 h-auto"
              color="white"
            />
          </a>
          <a
            href="https://www.linkedin.com/company/computer-society-of-india-upes/"
            target="_blank"
          >
            <FaLinkedin
              className="social-link white, w-7 h-auto"
              color="white"
            />
          </a>
          <a
            href="https://youtube.com/@UPESCSIOfficial?si=k3t6NWMvkzVyTQF7"
            target="_blank"
          >
            <FaYoutube
              className="social-link white, w-7 h-auto"
              color="white"
            />
          </a>
        </div>
        <div className="d-flex justify-content-center align-items-center gap-4">
          <a
            href="https://merchant.razorpay.com/policy/OAG5Tj7G48iTNQ/terms"
            target="_blank"
            style={{ color: "white", fontSize: "14px" }}
          >
            Terms and Conditions
          </a>
          <a
          href="https://merchant.razorpay.com/policy/OAG5Tj7G48iTNQ/refund"
          target="_blank"
          style={{ color: "white", fontSize: "14px" }}
        >
          Refund Policy
        </a>
        <a
          href="https://merchant.razorpay.com/policy/OAG5Tj7G48iTNQ/shipping"
          target="_blank"
          style={{ color: "white", fontSize: "14px" }}
        >
          Shipping Policy
        </a>
        <a
        href="https://merchant.razorpay.com/policy/OAG5Tj7G48iTNQ/privacy"
        target="_blank"
        style={{ color: "white", fontSize: "14px" }}
      >
        Privacy Policy
      </a>
      <a
        href="https://merchant.razorpay.com/policy/OAG5Tj7G48iTNQ/contact_us"
        target="_blank"
        style={{ color: "white", fontSize: "14px" }}
      >
        Contact Us
      </a>
        

        </div>

        <p style={{ color: "white", fontSize: "14px" }}>
          Copyright 2024 © UPES-CSI
        </p>
      </div>
    </footer>
  );
}
