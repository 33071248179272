const data = {
    "info": <><h1 style={{ fontSize: "1.5rem", fontWeight: "600" }}>Pratham Kandari</h1>
        <br></br>
        <ul className="aayu-det">
            <li>
                I am Aayushman Gusain, final year student, B.Tech CSE(Hons.); AIML
            </li>
            <li>
                Vice president, UPES-CSI.
            </li>
            <li>
                Anchor for 5 national and 2 International Hackathon.
            </li>
        </ul>
    </>
}

export default data;